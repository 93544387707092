exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-color-combinations-js": () => import("./../../../src/pages/color-combinations.js" /* webpackChunkName: "component---src-pages-color-combinations-js" */),
  "component---src-pages-colors-js": () => import("./../../../src/pages/colors.js" /* webpackChunkName: "component---src-pages-colors-js" */),
  "component---src-pages-core-values-js": () => import("./../../../src/pages/core-values.js" /* webpackChunkName: "component---src-pages-core-values-js" */),
  "component---src-pages-design-system-js": () => import("./../../../src/pages/design-system.js" /* webpackChunkName: "component---src-pages-design-system-js" */),
  "component---src-pages-device-mockups-js": () => import("./../../../src/pages/device-mockups.js" /* webpackChunkName: "component---src-pages-device-mockups-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-js": () => import("./../../../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-presentations-js": () => import("./../../../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-purpose-js": () => import("./../../../src/pages/purpose.js" /* webpackChunkName: "component---src-pages-purpose-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-value-proposition-js": () => import("./../../../src/pages/value-proposition.js" /* webpackChunkName: "component---src-pages-value-proposition-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-pages-wallpapers-js": () => import("./../../../src/pages/wallpapers.js" /* webpackChunkName: "component---src-pages-wallpapers-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

